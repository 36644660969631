import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ProjectHeader from './ProjectHeader';
import { useApi } from '../../../context/ApiContext';
import { SURVEY_STATUS, SURVEY_STATUS_CLOSED, SURVEY_STATUS_OPEN, SURVEY_STATUS_WRITING } from '../../../common/codeHelper';
import { useProject } from '../../../context/ProjectContext';
import { handleSelect, initPopupMenu } from '../../../common/javascriptHelper';
import Pagination from '../../../component/Pagination';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import toast from 'react-hot-toast';
import SurveyScaleSourcePopup from '../../survey/popup/SurveyScaleSourcePopup';
import SurveyNamePopup from '../../survey/popup/SurveyNamePopup';
import SurveyExportPopup from '../../survey/popup/SurveyExportPopup';
import SurveyLinkPopup from '../../survey/popup/SurveyLinkPopup';
import { markKeyword } from '../../../common/stringHelper';

const ProjectHome = props => {

  const navigate = useNavigate()
  const api = useApi()
  const {popup, confirm, alert} = useCommonPopup()
  const { projectId } = useParams()

  const project = useProject()

  const [surveyList, setSurveyList] = useState()

  const [pageLimit, setPageLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [originalTotalCount, setOriginalTotalCount] = useState(0)

  const [keyword, setKeyword] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("ALL")
  var searchKeyword = useRef("")
  var searchPage = useRef(0)

  /*=========================================================
   *  useEffect
   =========================================================*/

  useEffect(_ => {

  }, [])

  useEffect(_ => {

  }, [project.projectData])

  useEffect(_ => {

    initPopupMenu()

  }, [surveyList])

  useEffect(_ => {

    if (searchPage.current == currentPage) return
    getSurveyList(keyword, selectStatus, currentPage, pageLimit)

  }, [currentPage])

  useEffect(_ => {

    getSurveyList(keyword, selectStatus, currentPage, pageLimit)

  }, [pageLimit])

  useEffect(_ => {

    if (searchKeyword.current !== keyword) {
      getSurveyList(keyword, selectStatus, 0, pageLimit)
    }
  }, [keyword])

  /*=========================================================
   *  Data
   =========================================================*/
  const createSurvey = async () => {

    const result = await api.request("/surveys/", {
      method: "POST",
      params: {
        project: projectId,
      }
    })

    if (result?.status !== 201) {
      api.networkError(result, alert)
      return null
    } else {
      return result.data.survey
    }
  }

  const getSurveyList = async (search, status, page, limit) => {

    searchKeyword.current = search

    const result = await api.post("getSurveyList", {
      project: project.projectId,
      search: search,
      status: status,
      page: page,
      limit: limit
    })
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
    } else {
      searchPage.current = page
      setCurrentPage(page)
      setTotalCount(result.data.data.totalCount)
      setOriginalTotalCount(result.data.data.originalTotalCount)

      var list = result.data.data.surveys
      list.forEach((data, index) => {
        data["display_name"] = markKeyword(data.name, search)
      });
      setSurveyList(list)
    }
  }

  /*=========================================================
   *  Handler
   =========================================================*/

  const onChangePage = (page) => {
    setCurrentPage(page)
  }

  const onChangePageLimit = (limit) => {

    const index = currentPage * pageLimit
    const page = Math.floor(index / limit)
    setCurrentPage(page)
    setPageLimit(limit)
  }

  const onChangeInput = (e) => {

    var value = e.target.value
    if (keyword === value) return

    setKeyword(value)
  }

  const onKeyEvent = (e) => {

    if (e.key === 'Enter') {
      onClickSearch()
    }
  }

  const onClickStatus = (e) => {
    handleSelect(e)
  }

  const selectStatus = async (status) => {

    setSelectedStatus(status)
    await getSurveyList(keyword, status, 0, pageLimit)
  }

  const onClickSearch = async () => {

    await getSurveyList(keyword, selectStatus, 0, pageLimit)
  }

  const onClickNewSurvey = async () => {

    const surveyId = await createSurvey()
    if (!!surveyId) {
      navigate(`/project/${projectId}/survey/${surveyId}`)
    }
  }

  const onClickSurvey = async (survey) => {

    navigate(`/project/${projectId}/survey/${survey.idsurvey}`)
  }

  const onClickExport = async (survey) => {

    var result = await popup(
      <SurveyExportPopup
        projectData={project.projectData}
        surveyData={survey}
      />, {
        container: "project survey",
        name: "SurveySendPopup",
        style: "survey_send",
        useContainer: false,
      })

    if (result === false) return
    if ( !!result?.errors ) return

    await popup(
      <SurveyLinkPopup
        projectData={project.projectData}
        surveyData={survey}
        link={result}
      />,
      {
        container: "project survey",
        name: "SurveyLinkPopup",
        style: "survey_send",
        useContainer: false,
      },
    )
    getSurveyList(keyword, selectStatus, currentPage, pageLimit)
  }

  const onClickSource = async (survey) => {

    popup(
      <SurveyScaleSourcePopup
        surveyId={survey.idsurvey}
      />, {
        container: "survey",
        name:"popup-survey-scale-source",
        style:"view_source",
        hasFooter:false,
        title: "출처 보기"
      })
  }

  const onClickRenameSurvey = async (survey) => {

    if ( await popup(
      <SurveyNamePopup
        surveyData={survey}
      />, {
        container: "project",
        name: "project_home_pop",
        style: "edit",
        useContainer: false,
      }) !== true) return

      getSurveyList(keyword, selectStatus, currentPage, pageLimit)
  }

  const onClickCloseSurvey = async (survey) => {

    if ( await confirm(
      `설문을 종료하면 설문에 대한 접근이 차단되며 참여자들은 설문을 더 이상 볼 수 없습니다.<br/>
      한 번 종료된 설문을 다시 진행하려면 새로운 복사본을 생성하여  설문을 시작해야 합니다.<br/><br/>
      설문을 종료하시겠습니까?`, {
        title: "설문 종료",
        confirm: "종료",
        style: "medium"
      }) !== true) return

    const result = await api.post("closeSurvey", {
      survey: survey.idsurvey,
    })
    if (result?.data?.result != "ok") {
      api.networkError(result, alert)
      return
    } else {
      getSurveyList(keyword, selectStatus, currentPage, pageLimit)
    }
  }

  const onClickCopyLink = async (survey) => {

    await popup(
      <SurveyLinkPopup
        projectData={project.projectData}
        surveyData={survey}
        link={survey.link}
      />,
      {
        container: "project survey",
        name: "SurveyLinkPopup",
        style: "survey_send",
        useContainer: false,
      },
    )
  }

  const onClickDeleteSurvey = async (survey) => {

    if ( await confirm(
      `이 설문을 삭제하시겠습니까?<br/><br/>
      설문을 삭제하면 모든 공동 작업자가 더 이상 해당 설문을 볼 수 없게 되며, 설문이 완전히 삭제됩니다.`, {
        title: "설문 삭제",
        confirm: "삭제"
      }) !== true) return

    const result = await api.request(`/surveys/${survey.idsurvey}/`, {
      method: "DELETE",
    })
    if (result?.status !== 204) {
      api.networkError(result, alert)
      return
    } else {
      getSurveyList(keyword, selectStatus, currentPage, pageLimit)
    }
  }

  const onClickCopySurvey = async (survey) => {

    const result = await api.request(`/surveys/${survey.idsurvey}/copy/`, {
      method: "POST",
    })
    if (result?.status !== 201) {
      api.networkError(result, alert)
      return
    } else {
      getSurveyList(keyword, selectStatus, currentPage, pageLimit)
      toast.success("설문 복사가 완료되었습니다.")
    }
  }

  const SurveyItem = (props) => {

    const {item, index} = props

    useEffect(() => {

      initPopupMenu()

    }, [props])

    return (
      <tr key={`survey-${index}`}>
        <td style={{cursor: "pointer"}} onClick={() => onClickSurvey(item)}><i className="icon-filetext" ></i></td>
        <td style={{cursor: "pointer"}} onClick={() => onClickSurvey(item)}>
          {item.display_name?.length > 0 ? <div dangerouslySetInnerHTML={ {__html: item.display_name} }></div>
          : <span>제목없음</span>
          }
        </td>
        <td><span className={`tag ${item.status === SURVEY_STATUS_WRITING ? "hold" : item.status === "CLOSED" ? "end" : ""}`}>{SURVEY_STATUS[item.status]}</span></td>
        <td>{item.answercount}{item.newanswercount > 0 ? `(${item.newanswercount})`: ""} {item.newanswercount > 0 && <span className="dot"></span>}</td>
        <td>{item.updatedate.replaceAll("-", ".")}<br/>by {item.editor}</td>
        <td className='popmenu'>
          <i className="icon-dot-menu"></i>
          {item.status == SURVEY_STATUS_OPEN ? (
          <ul className="menu_pop">
            {project.projectData?.eCRF !== 1 && <li className='survey_del_btn'onClick={() =>onClickCopyLink(item)}>링크 복사</li> }
            <li className='survey_del_btn'onClick={() =>onClickCloseSurvey(item)}>설문 종료</li>
            <li className='survey_del_btn'onClick={() =>onClickCopySurvey(item)}>설문 복사</li>
            {item.hasscale === 1 && <li className='view_btn' onClick={() =>onClickSource(item)}>출처 보기</li>}
            <li className='view_btn' onClick={() =>onClickRenameSurvey(item)}>이름 바꾸기</li>
          </ul>
          ) : item.status == SURVEY_STATUS_CLOSED ? (
            <ul className="menu_pop">
              <li className='survey_send_btn' onClick={() =>onClickDeleteSurvey(item)}>설문 삭제</li>
              <li className='survey_del_btn'onClick={() =>onClickCopySurvey(item)}>설문 복사</li>
              {item.hasscale === 1 && <li className='view_btn' onClick={() =>onClickSource(item)}>출처 보기</li>}
          </ul>
          ) : (
          <ul className="menu_pop">
            <li className='survey_send_btn'onClick={() =>onClickExport(item)}>설문 보내기</li>
            <li className='survey_del_btn'onClick={() =>onClickCopySurvey(item)}>설문 복사</li>
            <li className='survey_del_btn' onClick={() => onClickDeleteSurvey(item)}>설문 삭제</li>
            {item.hasscale === 1 && <li className='view_btn' onClick={() =>onClickSource(item)}>출처 보기</li>}
            <li className='view_btn' onClick={() =>onClickRenameSurvey(item)}>이름 바꾸기</li>
          </ul>
          )}
        </td>
      </tr>
    )
  }

  /*=========================================================
   *  Render
   =========================================================*/

  return (
    <div className="body project">
      <ProjectHeader/>
      <div className="container">
        <div className="inwrap">
          <div className={`full gap ${!!surveyList && originalTotalCount == 0 ? "no_item": ""}`}>
          <section className="">
            <div className="search_wrap">
              <div>
                <div className="search_box">
                  <input type="text" placeholder="설문이름으로 검색" value={keyword} onChange={onChangeInput} onKeyUp={onKeyEvent}/>
                  <button onClick={onClickSearch}><i className="icon-search"></i></button>
                </div>
                <div className="selectBox">
                  <span className="label" onClick={onClickStatus}>설문 상태 : 전체</span>
                  <ul className="optionList">
                    <li className="optionItem" onClick={()=> {selectStatus("ALL")}}>설문 상태 : 전체</li>
                    <li className="optionItem" onClick={()=> {selectStatus("OPEN")}}>설문 진행 중</li>
                    <li className="optionItem" onClick={()=> {selectStatus("WRITING")}}>설문 대기</li>
                    <li className="optionItem" onClick={()=> {selectStatus("CLOSED")}}>설문 종료</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          { !!surveyList && (
              originalTotalCount > 0 ? (
                <section className="">
                  <div className="search_wrap">
                    <div>
                      <p>{keyword.length > 0 ? `검색 결과 (${totalCount}개)` : `전체 (${totalCount}개)`}</p>
                    </div>
                    <button className="primary large new_project_but" onClick={onClickNewSurvey}> <i>+</i> 설문 만들기</button>
                  </div>
                  <div className="section_in">
                    {surveyList.length > 0 ? (
                      <>
                        <table className="line">
                          <colgroup>
                            <col className="no"/>
                            <col />
                            <col className="date"/>
                            <col className="col100"/>
                            <col className="col200"/>
                            <col className="no"/>
                          </colgroup>
                          <thead>
                            <tr>
                              <th></th>
                              <th>설문이름</th>
                              <th>상태</th>
                              <th>응답</th>
                              <th>최종 수정일</th>
                              <th></th>
                          </tr>
                          </thead>
                          <tbody>
                            {surveyList.map((item, index) =>
                              <SurveyItem key={`survey-${index}`} index={index} item={item}/>
                              // <tr key={`survey-${index}`}>
                              //   <td onClick={() => onClickSurvey(item)}><i className="icon-answer" ></i></td>
                              //   <td onClick={() => onClickSurvey(item)}>
                              //     {item.display_name?.length > 0 ? <div dangerouslySetInnerHTML={ {__html: item.display_name} }></div>
                              //     : <span>제목없음</span>
                              //     }
                              //   </td>
                              //   <td><span className={`tag ${item.status === SURVEY_STATUS_WRITING ? "hold" : item.status === "CLOSED" ? "end" : ""}`}>{SURVEY_STATUS[item.status]}</span></td>
                              //   <td>{item.answercount}{item.newanswercount > 0 ? `(${item.newanswercount})`: ""} {item.newanswercount > 0 && <span className="dot"></span>}</td>
                              //   <td>{item.updatedate.replaceAll("-", ".")}<br/>by {item.editor}</td>
                              //   <td className='popmenu'>
                              //     <i className="icon-dot-menu"></i>
                              //     {item.status == SURVEY_STATUS_OPEN ? (
                              //     <ul className="menu_pop">
                              //       <li className='survey_del_btn'onClick={() =>onClickCloseSurvey(item)}>설문 종료</li>
                              //       {project.projectData?.eCRF !== 1 && <li className='survey_del_btn'onClick={() =>onClickCopyLink(item)}>링크 복사</li> }
                              //       <li className='survey_del_btn'onClick={() =>onClickCopySurvey(item)}>설문 복사</li>
                              //       <li className='view_btn' onClick={() =>onClickSource(item)}>출처 보기</li>
                              //       <li className='view_btn' onClick={() =>onClickRenameSurvey(item)}>이름 바꾸기</li>
                              //     </ul>
                              //     ) : item.status == SURVEY_STATUS_CLOSED ? (
                              //       <ul className="menu_pop">
                              //         <li className='survey_send_btn' onClick={() =>onClickDeleteSurvey(item)}>설문 삭제</li>
                              //         <li className='survey_del_btn'onClick={() =>onClickCopySurvey(item)}>설문 복사</li>
                              //         <li className='view_btn' onClick={() =>onClickSource(item)}>출처 보기</li>
                              //     </ul>
                              //     ) : (
                              //     <ul className="menu_pop">
                              //       <li className='survey_send_btn'onClick={() =>onClickExport(item)}>설문 보내기</li>
                              //       <li className='survey_del_btn'onClick={() =>onClickCopySurvey(item)}>설문 복사</li>
                              //       <li className='survey_del_btn' onClick={() => onClickDeleteSurvey(item)}>설문 삭제</li>
                              //       <li className='view_btn' onClick={() =>onClickSource(item)}>출처 보기</li>
                              //       <li className='view_btn' onClick={() =>onClickRenameSurvey(item)}>이름 바꾸기</li>
                              //     </ul>
                              //     )}
                              //   </td>
                              // </tr>
                              )}
                          </tbody>
                        </table>
                        <Pagination
                            totalCount={totalCount}
                            currentPage={currentPage}
                            pageSize={pageLimit}
                            changePageLimit={onChangePageLimit}
                            changeCurrentPage={onChangePage}
                      />
                      </>
                    ) : (
                    <p className='center'>
                      <br/><br/>
                      검색조건과 일치하는 설문이 없습니다.
                    </p>
                    )
                  }
                  </div>
                </section>
              ) : (
                <section className="project">
                  <div>
                    <img src="../../../assets/images/no_items.png" alt=""/>
                  </div>
                  <div>
                    <p>
                      아직 만들어진 설문이 없습니다. <br/>
                      설문 만들기 버튼을 눌러서 시작해보세요.
                    </p>
                  </div>
                  <div>
                    <button className="primary large new_project_but" onClick={onClickNewSurvey}> <i>+</i> 설문 만들기</button>
                  </div>
                </section>
              )
            )
          }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectHome;