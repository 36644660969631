import { useEffect, useRef, useState } from "react";
import { useCommonPopup } from "../../../context/CommonPopupContext";
import { useProject } from "../../../context/ProjectContext";
import { useApi } from "../../../context/ApiContext";
import { HISTORY_ACTION, PARTICIPANT_STATUS, SURVEY_STATUS_OPEN } from "../../../common/codeHelper";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ParticipantDetailHeader from "./ParticipantDetailHeader";
import RegisterAgreementPopup from "../popup/RegisterAgreementPopup";
import AgreementPreviewPopup from "../popup/AgreementPreviewPopup";
import MemoPopup from "../popup/MemoPopup";
import { isMobile } from "react-device-detect";
import { getFileData } from "../../../common/imageHelper";
import { useLoadingIndicator } from "../../../context/LoadingIndicatorContext";
import ChangeInitialPopup from "../popup/ChangeInitialPopup";
import { formatMobilePhone } from "../../../common/stringHelper";
import ChangePhonePopup from "../popup/ChangePhonePopup";

const ParticipantDetail = props => {

  const location = useLocation()
  const api = useApi()
  const navigate = useNavigate()

  const { popup, alert, confirm } = useCommonPopup()
  const {showLoading, stopLoading} = useLoadingIndicator()

  const project = useProject()
  const { participantId } = useParams()
  const [participant, setParticipant] = useState()
  const [schedule, setSchedule] = useState()
  const [history, setHistory] = useState()
  const fileInputRef = useRef()
  const affiliation = useRef()

  /*=========================================================
   *  useEffect 
   =========================================================*/

  useEffect(_ => {
    
    getParticipantInfo()

  }, [])

  useEffect(_ => {
    affiliation.current = location.state?.affiliation

  }, [location])

  /*=========================================================
   *  Data 
   =========================================================*/
   const getParticipantInfo = async () => {

    const result = await api.post("getParticipantInfo", {
      project : project.projectId,
      participant : participantId,
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {
      setParticipant(result.data.data.participant)
      setSchedule(result.data.data.schedule.map((item) => ({
          ...item,
          display : item.istotal === 1 ? "전체" : item.sections.map((section) => (section.name) ).join("<br/>"),
        }
      )))   
      setHistory(result.data.data.history.map((item) => ({
          ...item,
          display : getHistoryDisplay(item)
        }
      )))  
    }
  }
    
  const getHistoryDisplay = (history) => {
    if (history.action === "CHANGE_STATUS") {
      return `${HISTORY_ACTION[history.action]} (${PARTICIPANT_STATUS[history.beforestatus]} → <b>${PARTICIPANT_STATUS[history.afterstatus]})</b>`
    } else if (history.action === "NEW_MEMO") {
      return `${history.surveyname} 설문의 ${history.sectionorderno}-${history.questionorderno} 번 문항 <b>새 메모</b> 작성`
    } else if (history.action === "DELETE_MEMO") {
      return `${history.surveyname} 설문의 ${history.sectionorderno}-${history.questionorderno} 번 문항 <b>메모 삭제</b>`
    } else if (history.action === "SEND_LINK") {
      return `${history.surveyname} 설문 링크 카카오톡 전송`
    }
    return ""
  }

  /*=========================================================
   *  Handler 
   =========================================================*/
  const onClickChangeInitial = async (participant) => {

    const result = await popup(
      <ChangeInitialPopup
        projectData={project.projectData}
        participant={participant}
      />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantInfo()
    }
  }

  const onClickChangePhone = async (participant) => {

    const result = await popup(
      <ChangePhonePopup
        projectData={project.projectData}
        participant={participant} />,
      {
        container: "project",
        name: "Participant_pop2",
        style: "height_auto",      
        hasFooter: false,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantInfo()
    }
  }

  const onClickRegistAgreement = async () => {

    const result = await popup(
      <RegisterAgreementPopup 
        projectId={project.projectId}
        participant={participant}
      />,
      {
        container: "project",
        name: "Participant_pop4",
        style: "height_auto", 
        title: "서면 동의서 업로드",
        hasFooter: false,
      }, 
    )
    if (result === true) {
      getParticipantInfo()
    }
  }

  const onClickImage = async (e) => {

    let file = e.target.files[0]
    if (file == null) return

    let data = await getFileData(file)
    if (data == null) return
   
    showLoading()
    const result = await api.post("uploadParticipantAgreement", {
      project: project.projectId,
      participant: participantId,
      file: data
    })
    stopLoading()

    if (result?.data?.result != "ok") {
      // api.networkError(result, alert)
      await alert("이미지 업로드에 실패했습니다.<br/>이미지 사이즈를 확인하시고 다시 시도해 주세요.", {title: "이미지 업로드"})
    } else {
      getParticipantInfo()
    }
  }
 
  const onClickAgreementPreview = async () => {

    const result = await popup(
      <AgreementPreviewPopup 
        projectId={project.projectId}
        participant={participant}
      />,
      {
        container: "project",
        name: "Participant_pop5",
        style: "height_auto", 
        title: `${participant.identifier} 서면 동의서`,
        useContainer: false,
      }, 
    )
    if (result === true) {
      getParticipantInfo()
    }
  }

  const onClickRegistAgreementForStart = async (survey) => {

    if (await confirm("설문을 진행하기 위해서는 서면 동의서를 우선적으로 업로드해야 합니다.<br/>지금 업로드를 진행하시겠어요?", 
      {title: "서면 동의서 업로드"}
    ) === false) return 

    if (isMobile) {
      const result = await popup(
        <RegisterAgreementPopup 
          projectId={project.projectId}
          participant={participant}
        />,
        {
          container: "project",
          name: "Participant_pop4",
          style: "height_auto", 
          title: "서면 동의서 업로드",
          hasFooter: false,
        }, 
      )
      if (result !== true) return
      
      await getParticipantInfo()
      onClickStartAnswer(survey)
    } else {
      fileInputRef.current.click()
    }
  }

  const onClickImageForStart = async (e, survey) => {

    // if (await confirm("설문을 진행하기 위해서는 서면 동의서를 우선적으로 업로드해야 합니다.<br/>지금 업로드를 진행하시겠어요?", 
    //   {title: "서면 동의서 업로드"}
    // ) === false) return 

    let file = e.target.files[0]
    if (file == null) return

    let data = await getFileData(file)
    if (data == null) return
   
    showLoading()
    const result = await api.post("uploadParticipantAgreement", {
      project: project.projectId,
      participant: participantId,
      file: data
    })
    stopLoading()

    if (result?.data?.result != "ok") {
      // api.networkError(result, alert)
      await alert("이미지 업로드에 실패했습니다.<br/>이미지 사이즈를 확인하시고 다시 시도해 주세요.", {title: "이미지 업로드"})
    } else {      
      await getParticipantInfo()
      onClickStartAnswer(survey)      
    }
  }
 
  const onClickStartAnswer = async (survey) => {

    const result = await api.post("startAnswer", {
      project : Number(project.projectId),
      survey : Number(survey.idsurvey),
      participant : Number(participantId),
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)       
    } else {
      if (!!result.data.data.accesstoken) {
        const uri = `/answer?accesstoken=${result.data.data.accesstoken}`
        if (isMobile) {
          navigate(uri)
        }
        else {
          window.open(uri)
        }
      }
    }
  }

  const onClickShowAnswer = async (survey) => {
    window.open(`/project/${project.projectId}/survey/${survey.idsurvey}/answer/individual?participant=${participantId}&affiliation=${affiliation.current?? "MY"}`)  
  }

  const onClickSurvey = (survey) => {
    window.open(`/project/${project.projectId}/survey/${survey.idsurvey}`)  
  }

  const onClickSendLink = async (survey) => {
    const hasPhone = participant?.phone?.length > 0
    const initial = participant?.initial
    const title = "설문 링크 전송하기"
    if (!hasPhone) {
      alert(`${initial}님의 휴대전화 정보가 등록되어 있지 않습니다.<br/>번호를 등록 후 사용해 주세요.`, {title})
      return
    }

    if (await confirm(`${survey.name} 설문을 ${initial}님의 카카오톡으로 전송하시겠습니까?`, {title}) === false) return

    let result = await api.post("startAnswer", {
      project : Number(project.projectId),
      survey : Number(survey.idsurvey),
      participant : Number(participantId),
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)       
    } else {
      const accessToken = result.data.data.accesstoken;
      if (!!accessToken) {
        const uri = `/answer?accesstoken=${accessToken}`
        result = await api.post("sendLink", {
          project : Number(project.projectId),
          survey : Number(survey.idsurvey),
          participant : Number(participantId),
          uri
        })
        if (result?.data?.result === "ok") {
          getParticipantInfo()
        }
        else {
          api.networkError(result, alert)
        }
      }
    }
  }

  const QuestionItem = (props) => {

    const {item} = props

    const [showMemo, setShowMemo] = useState(false)

    const onClickMemo = () => {
      setShowMemo(!showMemo)
    }

   
    const onClosedMemo = () => {
      setShowMemo(false)
      getParticipantInfo()
    }

    const canStartSurvey = participant?.status === "PROCEED" && item.status === SURVEY_STATUS_OPEN
    const isSurveyCompleted = item.completed === 1
    const isNotCompletedOrMultipleResponse = !isSurveyCompleted || item.multipleresponse === 1

    return (
      <tr >
        {/* 설문 목록 */}
        <td className='c_primary_6' onClick={() => onClickSurvey(item)}>{item.name?.length > 0 ? item.name : "제목없음"}</td>
        {/* 섹션 */}
        <td dangerouslySetInnerHTML={{__html: item.display}}></td>
        {/* 메모 */}
        <td className='center'>
          {item?.memothreads?.length === 0 ? ("-") : (<button className="text_only memo-btn" onClick={() => onClickMemo()}>{item?.memothreads.length}개</button>)}
          { showMemo && 
            <MemoPopup 
              projectId={project.projectId}               
              title={item.name}
              memothreads={item?.memothreads} 
              showAnswer={true}
              surveyData={item} 
              participantId={participantId} 
              affiliation={affiliation.current} 
              onClosed={onClosedMemo}
            /> 
          }
        </td>
        {/* 설문시작 */}
        <td>
        { canStartSurvey ? (
            isNotCompletedOrMultipleResponse ? (
              <button className="text_only agreeUp-btn" onClick={() => { onClickStartAnswer(item); } }>시작하기</button>
            ) : (
              <span>설문 완료</span>
            )
          ) : (
            <span>-</span>
          )
        }
        </td>
        {/* 카카오톡 전송 */}
        { canStartSurvey ? (
            isNotCompletedOrMultipleResponse ? (
              <td onClick={() => onClickSendLink(item)}>
                <span className='c_primary_6'>카카오톡 링크 전송하기</span>
              </td>
            ) : (
              <td><span>설문 완료</span></td>
            )
          ) : (
            <td><span>-</span></td>
          )
        }
        {/* 응답 */}
        { isSurveyCompleted ? (
            <td onClick={() => onClickShowAnswer(item)}>
              <span className='c_primary_6'>응답보기 ({item.completeddate} 완료)</span>
            </td>
          ): (
            <td>
              <span>미응답</span>
            </td>
          )
        }
      </tr>
    )
  }

  return (
    <div className="body project participant">
      <ParticipantDetailHeader participant={participant}/>
      <div className="container">
        <div className="inwrap">
          <div className="full gap">
          <section className="">
            <div className="">
              <table className="line">
                <colgroup>
                  <col className='date' />
                  <col className="date"/>
                  <col className="date"/>
                  <col className="date"/>
                  <col className="date"/>
                  <col className="col200"/>
                </colgroup>
                <thead>
                  <tr>
                    <th>소속기관</th>
                    <th>식별 ID</th>
                    <th>대상자 번호</th>
                    <th>참여자 그룹</th>
                    <th>서면 동의일</th>
                    <th>휴대전화번호</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{participant?.affiliation}</td>
                    <td>{participant?.identifier}</td>
                    <td className='c_primary_6' onClick={() => onClickChangeInitial(participant)}>{participant?.initial}</td>
                    <td>{participant?.groupNameFull}</td>
                    <td>
                      { participant?.agreementdate?.length > 0 ? 
                        <button className="text_only agreeUp-btn" onClick={() => {onClickAgreementPreview(participant)}}>{participant?.agreementdate}</button>
                        :  isMobile ? <button className="text_only agreeUp-btn" onClick={() => {onClickRegistAgreement(participant)}}>업로드</button>  
                        : (
                          <div className="filebox field" align="left" title='이미지 업로드'>                            
                            <label htmlFor="files" className="c_primary_6">업로드</label> 
                            <input type="file" id="files" name="files[]" accept="image/*" capture="environment" onChange={(e) => {onClickImage(e, participant)}}/>
                          </div>
                        ) 
                        // <button className="text_only agreeUp-btn" onClick={() => {onClickRegistAgreement(participant)}}>업로드</button>
                      }
                      {/* <button className="text_only agreeUp-btn" >{participant?.agreementdate?.length > 0 ? participant?.agreementdate : "업로드"}</button> */}
                      {/* <button className="text_only agreeView-btn" >2023.03.10.</button> */}
                    </td>
                    <td className="Participant-no">
                      <div>{formatMobilePhone(participant?.phone)}</div>
                      <button className="secondary gray Participant-btn" onClick={() => onClickChangePhone(participant)}>
                        {participant?.phone?.length > 0 ? "수정" : "등록"}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
           </div>  
          </section>

          { !!participant && participant?.status !== "WITHDRAW" &&
            <section className="">
              <div className="search_wrap">
                <div>
                  <h4>{participant?.groupNameFull} 스케줄</h4>
                </div>
              </div>  
              <div className="section_in height_auto">
                <table className="line">
                  <colgroup>
                    <col width="*" />
                    <col className="*"/>
                    <col className="col100"/>
                    <col className="date" />
                    <col className="col200" />
                    <col className="col200" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>설문 목록</th>
                      <th>섹션</th>
                      <th className='center'>메모</th>
                      <th>설문시작</th>
                      <th>카카오톡 전송</th>
                      <th>응답</th>
                    </tr>
                  </thead>
                  <tbody>
                    { schedule?.map((item, index) => 
                      <QuestionItem key={`schedule-${index}`}
                        item={item}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            </section>
          }

          <section className="">
            <div className="search_wrap">
              <div>
                <h4>변경 히스토리</h4>
              </div>
            </div>  
            <div className="section_in height_auto">
              <table className="line">
                <colgroup>
                  <col className="col100"/>
                  <col className="col200"/>
                  <col className="col200"/>
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>작업자</th>
                    <th>날짜</th>
                    <th>내용</th>
                  </tr>
                </thead>
                <tbody>
                  { history?.map((item, index) => 
                    <tr key={`history-${index}`}>
                      <td>{item.no}</td>    
                      <td>{item.adminname}</td>                      
                      <td>{item.createdate}</td>
                      <td dangerouslySetInnerHTML={ {__html: item.display} }/>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </section>
         </div>
        </div>
      </div>
    </div>
  )
}
export default ParticipantDetail;