import React from 'react';

const ChoiceContent = ({
  currentData,
  answer,
  reasons,
  isSelected,
  onToggleAnswer,
  onSelectAnswer,
  onInputReason
}) => {
  return (
    <ul className='radio checkbox'>
      {currentData.question.items.map((item, index) => {
        const shouldShowTextarea = (item.isetc && isSelected(item)) ||
                                   (currentData.question.inputreason && item.reasonguide !== null && isSelected(item));
        const placeholderText = item.reasonguide || "답변 입력";
        const itemReason = reasons[item.orderno] || "";

        return (
          <li key={`question-${index}`}>
            {currentData.question.multipleselect === 1 ? (
              <label>
                <input type="checkbox"
                  name="checkbox"
                  checked={isSelected(item)}
                  onChange={(e) => { onToggleAnswer(item, e.target.checked) }}/>
                <span>{item.content}</span>
                {shouldShowTextarea &&
                  <>
                    :
                    <textarea className={`solo etc ${itemReason.length === 300 ? 'danger' : ''}`}
                      id={`textarea_auto_${item.orderno}`}
                      placeholder={placeholderText}
                      rows="1"
                      value={itemReason}
                      onChange={(e) => onInputReason(item.orderno, e.target.value)}
                    />
                  </>
                }
              </label>
            ) : (
              <label>
                <input
                  type="radio"
                  name="question"
                  checked={isSelected(item)}
                  onChange={(e) => { onSelectAnswer(item) }}/>
                <span>{item.content}</span>
                {shouldShowTextarea &&
                  <>
                    :
                    <textarea className={`solo etc ${itemReason.length === 300 ? 'danger' : ''}`}
                      id={`textarea_auto_${item.orderno}`}
                      placeholder={placeholderText}
                      rows="1"
                      value={itemReason}
                      onChange={(e) => onInputReason(item.orderno, e.target.value)}
                    />
                  </>
                }
              </label>
            )}
          </li>
        )
      })}
    </ul>
  );
};

export default ChoiceContent;
