import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { useApi } from '../../../context/ApiContext';
import { useCommonPopup } from '../../../context/CommonPopupContext';
import { useSurvey } from '../../../context/SurveyContext';
import { handleSelect, initPopupMenu } from '../../../common/javascriptHelper';
import { getFileData, questionImgageUrl } from '../../../common/imageHelper';
import { SURVEY_QUESTION_TYPE, 
  SURVEY_QUESTION_TYPE_YESORNO,
  SURVEY_QUESTION_TYPE_MULTIPLECHOICE,
  SURVEY_QUESTION_TYPE_SCORE,
  SURVEY_QUESTION_TYPE_ESSAY,
  SURVEY_QUESTION_TYPE_DATE,
  SURVEY_STATUS_WRITING,
  SURVEY_STATUS_CLOSED, 
  SURVEY_QUESTION_TYPE_CONDITION_AGE,
  SURVEY_QUESTION_TYPE_CONDITION_BIRTHDAY,
  SURVEY_QUESTION_TYPE_CONDITION_PHONENO,
  SURVEY_QUESTION_TYPE_CONDITION_GENDER,
  SURVEY_QUESTION_TYPE_CONDITION_REGION,
  SURVEY_QUESTION_TYPE_CONDITION_EDUCATION,
  SURVEY_QUESTION_TYPE_CONDITION_MARRIAGE,
  SURVEY_QUESTION_OPTION} from '../../../common/codeHelper';
import QuestionItem from './QuestionItem';

const QuestionContent = props => {

  const api = useApi()
  const {confirm, alert} = useCommonPopup()

  const survey = useSurvey()
  var { section } = props
  
  // var [question, setQuestion] = useState(null)
  const {question} = props
  const oldData = useRef()
  const isLock = useRef(false)

  const timer = useRef({
    content: null,
    type: null,
  })

  const [grab, setGrab] = useState(null)
  const [draggable, setDraggable] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [imageData, setImageData] = useState()
  
  const nameRef = useRef()
  const imageRef = useRef()
  const [imageHeight, setImageHeight] = useState(5 / 4 * 50)
  /*=========================================================
   *  useEffect 
   =========================================================*/

  useEffect(_ => {
    
    return ()=>{ 
      clearTimeout(timer.current.content)
      clearTimeout(timer.current.type)
    }
  }, [])

  useEffect(() => {

    if (!imageRef.current) return
    setImageHeight(5 / 4 * imageRef.current.offsetWidth)

  }, [imageRef.current])

  useEffect(_ => {
    
    if (props.question?.idquestion !== oldData?.current?.idquestion || props.question?.hasDetail !== true) {      
      oldData.current = {
        ...props.question
      }
      getQuestion(props.question.idquestion)
      setImageData(null)
      
      const DEFAULT_HEIGHT = 2; 
      const $textarea = document.querySelector('#textarea_auto');
      if ($textarea) {
        const $target = $textarea;
        $target.style.height = 0;
        $target.style.height = DEFAULT_HEIGHT + $target.scrollHeight  + 'px';
      }

      initPopupMenu()  

      if (!!survey.showError && !!question) {
        if (survey.hasError(`question-${question.idquestion}`, "name")) {
          setTimeout(() => { 
            nameRef.current?.focus()
          }, 200)
        }       
      }

    } else  if (props.question?.type !== oldData?.current?.type 
      || props.question?.conditiontype !== oldData?.current?.conditiontype
      || props.question?.isconditional !== oldData?.current?.isconditional 
      || props.question?.items?.length !== oldData?.current?.items?.length) {    
      oldData.current = {
        ...props.question
      }  
      if (!!survey.errorData) {
        survey.checkError()
      }
      initPopupMenu()  
    } else {
      oldData.current = {
        ...props.question
      }
    }    

  }, [props])
  
  useEffect(_ => {

    if (!!survey.showError && !!question) {
      if (survey.hasError(`question-${question.idquestion}`, "name")) {
        setTimeout(() => { 
          nameRef.current?.focus()
        }, 200)
      }       
    }
  }, [survey.showError])

  /*=========================================================
   *  Data 
   =========================================================*/

  const getQuestion = async (idquestion) => {

    const result = await api.request(`/questions/${idquestion}/`);
    if (result?.status !== 200) {
      // api.networkError(result, alert)
      return null
    } else {   
      var data = result.data
     
      data["hasDetail"] = true
      data["hasetc"] = (data.items?.filter(item => 
        item.isetc === 1
      )?.length > 0) 

      data.items?.forEach((item, index) => {
        item["index"] = index
      });
      survey.updateQuestion(section, {
        ...question,
        ...data,
      })
    }
  }

  const updateQuestion = async (name, value, action, extra) => {

    if (question == null) return

    var params = {
      ...extra,
      question: question.idquestion,
      [name]: value 
    }

    const result = await api.post(action, params)

    return result?.data?.result == "ok"
  }
  
  const addItem = async (isetc = 0) => {

    if (question == null) return

    const result = await api.request("/items/", {
      method: "post",
      params: {
        question: question.idquestion,
        isetc: isetc
      }
    })
    return result?.status === 201
  }

  const updateItemOrder = async (iditem, orderno) => {

    if (question == null) return

    const result = await api.post("updateItemOrder", {
      item: iditem,
      orderno: orderno
    })
    return result?.data?.result == "ok"
  }
  
  const deleteQuestionImage = async () => {

    if (question == null) return

    const result = await api.post("deleteQuestionImage", {
      question: question.idquestion,
    })
    return result?.data?.result == "ok"
  }

  const updateData = async (name, value, action, immediate, extra) => {

    if (name !== 'type' && name !== 'conditiontype' && oldData.current[name] === value) return    

    if (immediate) {
      var result = await updateQuestion(name, value, action, extra)
      if (result) {
        await getQuestion(question.idquestion)
      }
    } else {
      survey.updateQuestion(section, {
        ...question,
        [name]: value
      })
      if (!!timer.current[name]) clearTimeout(timer.current[name])
      timer.current[name] = setTimeout(() => {
        updateQuestion(name, value, action, extra)
      }, survey.saveTimeout)
    }
  }

  const updateQuestionData = async (name, value, action, immediate, extra) => {

    if (name !== 'type' && name !== 'conditiontype' && oldData.current[name] === value) return    

    if (immediate) {
      const result = await api.request(`/questions/${question.idquestion}/`, {
        method: "patch",
        params: { [name]: value, ...extra }
      });
      if (result?.status === 200) {
        await getQuestion(question.idquestion)
      }
    } else {
      survey.updateQuestion(section, {
        ...question,
        [name]: value
      })
      if (!!timer.current[name]) clearTimeout(timer.current[name])
      timer.current[name] = setTimeout(() => {
        updateQuestion(name, value, action, extra)
      }, survey.saveTimeout)
    }
  }

  /*=========================================================
   *  Handler 
   =========================================================*/

  const onClickSelect = (e) => {

    if (survey.surveyData.status !== SURVEY_STATUS_WRITING) return
    handleSelect(e)
  }
  
  const onSelectType = async (condition, value) => {
    
    if (condition === 1) {
      await updateQuestionData("conditiontype", value, "updateQuestionType", true, {isconditional: 1})
    } else {
      await updateQuestionData("type", value, "updateQuestionType", true, {isconditional: 0})
    }
  }
  
  const onSelectScoreScale = (value) => {
    updateQuestionData("scale", value, "updateScoreQuestionScale", true)
  }

  const onSelectScoringOrder = (value) => {

    updateData("scoringorder", value, "updateScoreQuestionScoringOrder", true)
  }

  const onSelectStartScore = (value) => {

    updateData("startscore", value, "updateScoreQuestionStartScore", true)
  }
 
  const onChangeContent = (e) => {

    var value = e.target.value    
    e.target.style.height = 0;
    e.target.style.height = 2 + e.target.scrollHeight  + 'px';

    updateData("content", value, "updateQuestionContent", false)
  }

  const onClickAdd = async () => {

    if (isLock.current) return
    isLock.current = true

    var result = await addItem()
    if (result) {
      await getQuestion(question.idquestion)
    }
    isLock.current = false
  }


  // 옵션 메뉴 
  const onChangeScoring = async (e) => {
    
    var value = e.target.checked    
    updateData("scoring", value, "updateScoringOption", true)
  }

  const onChangeMandatory = async (e) => {
    
    var value = e.target.checked    
    updateData("mandatory", value, "updateMandatoryOption", true)
  }

  const onChangeMoveAfterAnswer = async (e) => {
    
    var value = e.target.checked    
    updateData("moveafteranswer", value, "updateMoveAfterAnswerOption", true)
  }

  const onChangeUseEtc = async (e) => {
    
    var value = e.target.checked    
    // updateData("moveafteranswer", value, "updateMoveAfterAnswerOption", true)
    if (value) {
      var result = await addItem(1)
      if (result) {
        await getQuestion(question.idquestion)
      }
    } else {
      const etcItem = question.items?.filter(item => 
        item.isetc === 1
      )?.[0]
      if (!!etcItem) {
        const result = await api.post("deleteItem", {
          item: etcItem.iditem,
        })
        if (result) {
        await getQuestion(question.idquestion)
        }
      }
    }
  }
  
  const onChangeInputreason = async (e) => {
    var value = e.target.checked    
    updateQuestionData("inputreason", value, "updateInputReasonOption", true)
  }

  const onChangeMultipleSelect = async (e) => {
    
    var value = e.target.checked    
    if (value === true && question?.moveafteranswer === 1) {
      if (await confirm(`한 문항에 여러 개의 답변을 받고 싶다면, 다중선택 옵션을 사용하세요.
       이 경우 답변을 기준으로 문항 이동 기능은 사용할 수 없습니다.<br/><br/>
       답변을 기준으로 문항 이동 기능을 사용하지 않고 다중선택 옵션을 적용하려면 확인 버튼을 눌러주세요.`,
       {title: "다중 선택 적용"})) {
        updateData("multipleselect", value, "updateMultipleSelectOption", true)
      }
    } else {
      updateData("multipleselect", value, "updateMultipleSelectOption", true)
    } 
  }

  const onClickImage = async (e) => {

    let file = e.target.files[0]
    if (file == null) return

    let data = await getFileData(file)
    if (data == null) return
   
    setImageData(data)
    updateData("file", data, "uploadQuestionImage", true)
  }

  const onClickDeleteImage = async (e) => {

    var result = await deleteQuestionImage()
    if (result) {
      await getQuestion(question.idquestion)
      setImageData(null)
    }
  }
  
  const onClickCopy = async () => {

    const result = await api.request(`/questions/${question.idquestion}/copy/`, { method: "post" })
    if (result?.status !== 201) {
      api.networkError(result, alert)
    } else {                
      await survey.getSurvey(section.idsection, result.data.question)
    }
  }

  const onClickDelete = async() => {
    
    const result = await api.post("deleteQuestion", {
      question: question.idquestion,
    })
    
    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {       
      await survey.getSurvey()
      toast.success(`문항이 삭제되었습니다.`); 
    }
  }

  // QuestionItem에서 내용 변경됐을 때 
  const onChangeItem = async () => {

    await getQuestion(props.question.idquestion)
  }
    
  // DragAndDrop
  const onDragOver = e => {
    e.preventDefault();
  }

  const onDragStart = (e) => {

    if (!draggable) {
      e.preventDefault()
      return 
    }

    setGrab(e.target);

    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);
  }

  const onDragEnd = (e) => {
    e.target.classList.remove("grabbing");
    e.dataTransfer.dropEffect = "move";
  }

  const findTargetPosition = (node) => {

    let targetPosition = Number(node.dataset.position);
    
    if (!isNaN(targetPosition)) {
      return targetPosition
    }
    if (node.parentNode != null) {
      return findTargetPosition(node.parentNode)
    }
    return -1
  }

  const onDrop = async (e) => {
    
    let grabPosition = Number(grab.dataset.position);
    let targetPosition = findTargetPosition(e.target)
        
    if (targetPosition === -1) return
    
    if (isNaN(grabPosition) || isNaN(targetPosition)) return

    const grabItem = question.items[grabPosition]
    const targetItem = question.items[targetPosition]

    if ( !grabItem || !targetItem) return

    var result = await updateItemOrder(grabItem.iditem, targetItem.orderno)
    if (result) {
      await getQuestion(question.idquestion)
    }
  }

  
  const YesOrNoContent = () => {

    return (
      <div className='in_padding flex_c gap question_body'>
        { question?.items?.map((item, index) =>
            <QuestionItem 
              key={`question-item-${index}`}
              index={index} 
              item={item} 
              question={question} 
              section={section} 
              onChangeItem={onChangeItem}
            />
          )
        }
        {question?.inputreason === 1 && 
          <div  className={`vertical_form gap yesno no_icon ${question?.scoring !== 1 ? "no_score" : ""}`}>
            <div>
              <span className='text'>사유</span>
              <input type="text" className='full disabled big solo' placeholder="사유" value='' disabled/>
            </div>
            <div></div>
          </div> 
        }     
      </div>
    )
  }

  const ChoiceContent = () => {
    return (
      <ul>
        <div className='in_padding flex_c gap question_body'>
          { question?.items?.map((item, index) =>
              <li 
                key={`question-item-${index}`}
                draggable={item.isetc !== 1}
                data-position={index}
                onDragOver={onDragOver}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}>
                  <QuestionItem 
                    index={index} 
                    item={item} 
                    question={question} 
                    section={section}                   
                    onChangeItem={onChangeItem} 
                    setDraggable={setDraggable}
                  />
              </li>
            )
          }
          {survey.surveyData?.status === SURVEY_STATUS_WRITING && 
            <div className={`vertical_form gap yesno ${question?.scoring !== 1 ? "no_score" : ""}`}>
              <div>
                <div >
                  <span className='num plus' onClick={onClickAdd}></span>
                  <button className='full' onClick={onClickAdd}>옵션 추가</button>
                </div>
              </div>
              <div>
              </div>
            </div> 
          }
        </div>
      </ul>
    )
  }

  const ScoreContent = () => {
   
    return (
      <div className='in_padding flex_c gap question_body point_type'>
        { survey.surveyData.status !== SURVEY_STATUS_CLOSED && 
          <>
            <div className='in_padding bg_gray point_preview'>
              <label htmlFor="">미리보기</label>
              <ul className=''>
                { question?.items?.map((item, index) =>
                    <li key={`score-item-${index}`} className={item === selectedItem ? "active" : ""} onClick={()=>{setSelectedItem(item)}}>{item?.score}</li>
                  )
                }
              </ul>
            </div>
            <div className='flex'>
              <div >
                <label htmlFor="">척도</label>
                <div className={`selectBox large ${survey.surveyData.status !== SURVEY_STATUS_WRITING ? "c_disabled" : ""}`}>       
                  <span className={`label ${survey.surveyData?.status !== SURVEY_STATUS_WRITING ? "c_disabled" : ""}`} 
                    onClick={onClickSelect}
                    >{question?.scale?? 0}점
                  </span>
                  <ul className="optionList">
                    <li className="optionItem" onClick={() => {onSelectScoreScale(4)}}>4점</li>
                    <li className="optionItem" onClick={() => {onSelectScoreScale(5)}}>5점</li>
                    <li className="optionItem" onClick={() => {onSelectScoreScale(7)}}>7점</li>
                    <li className="optionItem" onClick={() => {onSelectScoreScale(10)}}>10점</li>
                    <li className="optionItem" onClick={() => {onSelectScoreScale(11)}}>11점</li>
                  </ul>
                </div>
              </div>
              <div>
                <label htmlFor="">시작점</label>
                <div className={`selectBox large ${survey.surveyData.status !== SURVEY_STATUS_WRITING ? "c_disabled" : ""}`}>      
                  <span className={`label ${survey.surveyData?.status !== SURVEY_STATUS_WRITING ? "c_disabled" : ""}`} 
                    onClick={onClickSelect}
                  >{question?.startscore?? 0}점
                  </span>
                  <ul className="optionList">
                    <li className="optionItem" onClick={() => {onSelectStartScore(0)}}>0점</li>
                    <li className="optionItem" onClick={() => {onSelectStartScore(1)}}>1점</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="">채점 방식</label>
              <div className='flex'>                    
                <div className="radio">
                  <label>
                    <input type="radio" name="point" 
                      checked={question?.scoringorder !== 'DESC'} 
                      onChange={(e) => {if (e.target.checked) onSelectScoringOrder("ASC")}} 
                      disabled={survey.surveyData?.status !== SURVEY_STATUS_WRITING}
                    />
                    <span>점수대로 채점</span>
                  </label>
                </div>
                <div className="radio">
                <label>
                    <input type="radio" name="point" 
                      checked={question?.scoringorder === 'DESC'}
                      onChange={(e) => {if (e.target.checked) onSelectScoringOrder("DESC")}} 
                      disabled={survey.surveyData?.status !== SURVEY_STATUS_WRITING}
                    />
                    <span>점수의 역순으로 채점</span>
                  </label>
                </div>
              </div>
            </div>
          </>
        }
        
        <label htmlFor="">점수 설명</label>
        { question?.items?.map((item, index) =>
            <QuestionItem 
              key={`question-item-${index}`}
              index={index} 
              item={item}
              question={question} 
              section={section} 
              selectedItem={selectedItem}
              onChangeItem={onChangeItem} 
              setDraggable={setDraggable}
            />           
          )
        }
      </div>
    )
  }

  const essayContent = () => {
    return (
      <div className='in_padding question_body '>
        <textarea name="" id="" cols="30" rows="10" className='full font_16 disabled' placeholder='답변 입력 영역' disabled={true}></textarea>
        <p className="text_right c_disabled"> 0 / 100</p>
      </div>
    )
  }

  const dateContent = () => {
    return (
      <div className='in_padding question_body '>
        <input type="date" disabled/>
      </div>
    )
  }

  const getOption = (survey, question, option) => {
    const label = SURVEY_QUESTION_OPTION[option]
    const disabled = survey.surveyData.status !== SURVEY_STATUS_WRITING

    let checked = false;
    let onChange;

    if (option === "SCORING") {
      checked = question?.scoring === 1
      onChange = onChangeScoring
    }
    else if (option === "MOVE_AFTER_ANSWER") {
      checked = question?.moveafteranswer === 1
      onChange = onChangeMoveAfterAnswer
    }
    else if (option === "INPUT_REASON") {
      checked = question?.inputreason === 1
      onChange = onChangeInputreason
    }
    else if (option === "USE_ETC") {
      checked = question?.hasetc
      onChange = onChangeUseEtc
    }

    return (
      <li className={disabled ? "disabled" : ""}>
        <label>
          <input
            type="checkbox"
            name="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          <span>{label}</span>
        </label>
      </li>
    );
  }


  return (
    <section name="section">
      {/* <CommonPopup/> */}
      <div className='in_padding flex_c question_head'>      
        <div className='vertical_form gap align_item_top'>
          <div  className='Survey_num'>
            <span>{`${section?.orderno}-${question?.orderno}.`}</span>
            {/* <input type="text" className="full big solo" placeholder="문항" value={question?.content} onChange={onChangeContent}/> */}
            <textarea 
              className={`full big solo ${survey.hasError(`question-${question?.idquestion}`, "name") ? "error" : ""}`}
              ref={nameRef}
              id="textarea_auto" 
              placeholder="문항" rows={1} 
              value={question?.content} 
              onChange={onChangeContent}
              disabled={survey.surveyData?.status === SURVEY_STATUS_CLOSED}
            >
            </textarea>
          </div>          
          <div>
            <div className="box full">
              <div className={`selectBox large ${survey.surveyData.status !== SURVEY_STATUS_WRITING ? "c_disabled" : ""}`}>
                <span 
                  className={`label ${survey.surveyData.status !== SURVEY_STATUS_WRITING ? "c_disabled" : ""}`} 
                  onClick={onClickSelect}
                >{SURVEY_QUESTION_TYPE[question?.isconditional === 1 ? question?.conditiontype : question?.type]}
                </span>
                <ul className="optionList">
                  <li className="optionItem" onClick={() => {onSelectType(0, SURVEY_QUESTION_TYPE_MULTIPLECHOICE)}}>객관식</li>
                  <li className="optionItem" onClick={() => {onSelectType(0, SURVEY_QUESTION_TYPE_YESORNO)}}>네/아니요</li>
                  <li className="optionItem" onClick={() => {onSelectType(0, SURVEY_QUESTION_TYPE_SCORE)}}>점수형</li>
                  <li className="optionItem" onClick={() => {onSelectType(0, SURVEY_QUESTION_TYPE_ESSAY)}}>주관식</li>
                  <li className="optionItem" onClick={() => {onSelectType(0, SURVEY_QUESTION_TYPE_DATE)}}>날짜</li>

                  <li className="optionItem" onClick={() => {onSelectType(1, SURVEY_QUESTION_TYPE_CONDITION_AGE)}}>조건_나이대</li>
                  <li className="optionItem" onClick={() => {onSelectType(1, SURVEY_QUESTION_TYPE_CONDITION_BIRTHDAY)}}>조건_생일</li>
                  <li className="optionItem" onClick={() => {onSelectType(1, SURVEY_QUESTION_TYPE_CONDITION_PHONENO)}}>조건_휴대폰번호</li>
                  <li className="optionItem" onClick={() => {onSelectType(1, SURVEY_QUESTION_TYPE_CONDITION_GENDER)}}>조건_성별</li>
                  <li className="optionItem" onClick={() => {onSelectType(1, SURVEY_QUESTION_TYPE_CONDITION_REGION)}}>조건_지역</li>
                  <li className="optionItem" onClick={() => {onSelectType(1, SURVEY_QUESTION_TYPE_CONDITION_EDUCATION)}}>조건_학력</li>
                  <li className="optionItem" onClick={() => {onSelectType(1, SURVEY_QUESTION_TYPE_CONDITION_MARRIAGE)}}>조건_결혼유무</li>
                </ul>
              </div>  
            </div> 
          </div>          
        </div>
        { question?.image?.length > 0 || !!imageData ? (
            <div className="pip">
              <div>
                {!!imageData ? <img 
                    ref={imageRef} 
                    style={{maxHeight:`${imageHeight}px`, objectFit: "cover"}}
                    src={imageData}
                  /> : <img 
                    ref={imageRef} 
                    className="imageThumb" 
                    style={{maxHeight:`${imageHeight}px`, objectFit: "cover"}}
                    src={questionImgageUrl(question?.image, "small")}
                  />
                }                
              </div>
              <span className="remove" onClick={onClickDeleteImage}><i className='icon-delete survey-del-btn' title='삭제'></i></span>
            </div>
          ) : (null)
        }
      </div>
      { question?.type === SURVEY_QUESTION_TYPE_YESORNO && YesOrNoContent() }
      { question?.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE && ChoiceContent() }
      { question?.type === SURVEY_QUESTION_TYPE_SCORE && ScoreContent() }
      { question?.type === SURVEY_QUESTION_TYPE_ESSAY && essayContent()}
      { question?.type === SURVEY_QUESTION_TYPE_DATE && dateContent()}
      <div className='question_foot in_padding'>
        <p>
          <span>필수</span>
          <label className="switch">
            <input 
              type="checkbox" 
              checked={question?.mandatory === 1} 
              onChange={onChangeMandatory}
              disabled={survey.surveyData.status !== SURVEY_STATUS_WRITING}
            />
            <span className="slider round"></span>
          </label>
        </p>
        { question?.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE && 
          <p>
            <span>다중선택</span>
            <label className="switch">
              <input type="checkbox" 
                checked={question?.multipleselect === 1} 
                onChange={onChangeMultipleSelect}
                disabled={survey.surveyData.status !== SURVEY_STATUS_WRITING}
              />
              <span className="slider round"></span>
            </label>
          </p> 
        }
        { survey.surveyData.status === SURVEY_STATUS_WRITING && (
          <>
            <div className="filebox field" align="left" title='이미지 업로드'>
              <label htmlFor="files"><i className="icon-fileimage" ></i></label> 
              <input type="file" id="files" name="files[]" accept="image/*" /*multiple*/ onChange={onClickImage}/>
            </div>
            <span className='tooltip'>
              <i className="icon-copy" onClick={onClickCopy}></i>
              <span className="tooltiptext tooltip-top">문항 복사</span>
            </span> 
            {/* <i className='icon-copy' onClick={onClickCopy}></i> */}
            <i className='icon-delete survey-del-btn' onClick={onClickDelete}></i>
          </>
        )}
        {/* <div className='del_popup'>
          <div className='pop_in'>
          <p>삭제했습니다.</p>
          <button type='button' className="text_only">실행취소</button>
          </div>
        </div> */}
        { question?.type === SURVEY_QUESTION_TYPE_YESORNO &&
          <span className='popmenu'>
            <i className="icon-dot-menu " ></i>
            <ul className="menu_pop checkbox">
              {getOption(survey, question, 'SCORING')}
              {getOption(survey, question, 'MOVE_AFTER_ANSWER')}
              {getOption(survey, question, 'INPUT_REASON')}
            </ul>
          </span>
        }
        { question?.type === SURVEY_QUESTION_TYPE_MULTIPLECHOICE ? (
          question?.isconditional === 1 ? (
            <span className='popmenu'>
              <i className="icon-dot-menu " ></i>
              <ul className="menu_pop checkbox">
                {getOption(survey, question, 'MOVE_AFTER_ANSWER')}
                {getOption(survey, question, 'USE_ETC')}
                {getOption(survey, question, 'INPUT_REASON')}
              </ul>
            </span>
          ) : (
            <span className='popmenu'>
              <i className="icon-dot-menu " ></i>
              <ul className="menu_pop checkbox">
                {question?.isconditional !== 1 && getOption(survey, question, 'SCORING')}
                {getOption(survey, question, 'MOVE_AFTER_ANSWER')}
                {getOption(survey, question, 'USE_ETC')}
                {getOption(survey, question, 'INPUT_REASON')}
              </ul>
            </span>
          )
        ) : (null)
        }
      </div>
    </section>
  )
}
  
export default QuestionContent;