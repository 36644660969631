import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../../context/ApiContext';

import { useSurvey } from '../../../context/SurveyContext';
import SurveyAnswerSummary from './SurveyAnswerSummary';
import SurveyAnswerIndividual from './SurveyAnswerIndividual';
import { useProject } from '../../../context/ProjectContext';
import queryString from 'query-string';
import { SURVEY_QUESTION_TYPE, SURVEY_QUESTION_TYPE_DATE, SURVEY_QUESTION_TYPE_ESSAY, SURVEY_QUESTION_TYPE_SCORE } from '../../../common/codeHelper';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip'
import moment from 'moment';
import FileUploadPopup from '../popup/FileUploadPopup';
import { useCommonPopup } from '../../../context/CommonPopupContext';

const SurveyAnswerContent = props => {

  const { projectId, surveyId, tab, subTab } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { popup } = useCommonPopup()
  const api = useApi()
  const project = useProject()
  const survey = useSurvey()
  const [selectedTab, setSelectedTab] = useState("summary")
  const [affiliation, setAffiliation] = useState("MY")
  const participant = useRef()
  const idanswer = useRef()
  const [dataCount, setDataCount] = useState(0)
  const [fileCount, setFileCount] = useState(0)

  useEffect(_ => {

    const query = queryString.parse(location.search);
    if (!!subTab) {
      setSelectedTab(subTab)
      if (subTab === 'individual') {
        if (!!query?.participant) {
          participant.current = query?.participant
          if (!!query?.answer) {
            idanswer.current = Number(query?.answer)
          }
        }
      }
    }
    if (!!query?.affiliation) {
      setAffiliation(query.affiliation)
    }
    getFileCount()

  }, [location])

  useEffect(_ => {

    getFileCount()

  }, [project.projectData])

  useEffect(_ => {

    console.log(`userEffect[affiliation] ${affiliation}`)
    getAnswerCount()

  }, [affiliation])


  const onChangeTab = (subTab) => {

    navigate(`/project/${projectId}/survey/${surveyId}/${tab}/${subTab}`, {replace: true})
  }

  const onChangeAffiliation = (affiliation) => {

    setAffiliation(affiliation)
  }

  const onClickUpload = async () => {

    const result = await popup(
      <FileUploadPopup
        surveyId={surveyId}
      />,
      {
        // container: "survey",
        name: "FileUploadPopup",
        // style: "survey_scale",
        title: "표준화된 척도",
        useContainer: false
      },
    )
    getFileCount()
  }

  const onClickDownload = async (format) => {

    var result = await getDownloadData()
    if (!result) return

    const sections = result.sections
    const answers = result.answers

    if (sections?.length == 0 || answers?.length == 0) return

    if (format === "excel") {
      await saveExcel(sections, answers)
    } else if (format === "csv") {
      await saveCsv(sections, answers)
    }
  }

  const getAnswerCount = async () => {

    const result = await api.post("getAnswerCount", {
      survey: survey.surveyId,
      myaffiliation : affiliation === "MY" ? 1 : 0
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {
      var list = []
      var count = result.data.data.answercount
      setDataCount(count)
    }
  }

  const getFileCount = async () => {

    if (project.projectData?.eCRF !== 1) return

    const result = await api.post("getExcelForConfirmCount", {
      survey: survey.surveyId,
    })

    if (result?.data?.result !== "ok") {
      api.networkError(result, alert)
    } else {
      var list = []
      var count = result.data.data.count
      setFileCount(count)
    }
  }

  const getDownloadData = async () => {
    const result = await api.request(`/surveys/${survey.surveyId}/download/`, {
      method: "POST",
      params: { myaffiliation : affiliation === "MY" }
    })

    if (result?.status !== 200) {
      api.networkError(result, alert)
      return null
    } else {
      const {sections, answers} = result.data

      answers?.forEach((participant, k) => {
        participant["answerorder"] = k + 1
      })

      sections?.forEach((section, i) => {
        section.questions?.forEach((question, j) => {
          answers?.forEach((participant, k) => {
            let answer = getAnswer(participant.answerquestion, section.orderno, question.orderno)
            let score = getScore(question, answer?.answercontent?? "")
            let value = getValue(question, answer?.answercontent?? "", answer?.answerreason)
            let total = section[participant.answerorder]?.total?? null

            if (Number(score) != null && question.questiontype != SURVEY_QUESTION_TYPE_ESSAY
              && question.questiontype != SURVEY_QUESTION_TYPE_DATE && question.scoring === 1) {
              if (total === null) total = 0
              total += Number(score)
              section[participant.answerorder] = {
                ...section[participant.answerorder],
                total : Number(total)
              }
            }
            question[participant.answerorder] = {
              score: score?? "",
              value: value
            }

            if (j === 0) section[participant.answerorder] = {
              ...section[participant.answerorder],
              starttime: answer?.createdate?? ""
            }
            else if (j === section.questions?.length - 1) section[participant.answerorder] = {
              ...section[participant.answerorder],
              lasttime: answer?.updatedate?? "",
            }
          })
        });
      });

      return result.data
    }
  }
  const getRawDataset = (eCRF, sections, answers, csv) => {

    var timestamps = ["", "", "", "Timestamp"]
    var ips = ["", "", "", "IP"]
    var columns1 = ["", "", "", "문항"]
    var columns2 = ["No.", "section", "question", ""]
    var datas = csv ? [timestamps, ips, columns1, columns2] : []

    if (csv) {
      answers?.forEach(participant => {
        timestamps.push(participant.completeddate)
        ips.push(participant.ipatcompleted)
        columns1.push(eCRF ? participant.initial : participant.accesstoken)
        timestamps.push("")
        ips.push("")
        columns1.push("")
        columns2.push("score")
        columns2.push("value")
      });
    }

    var index = 0
    sections?.forEach(section => {
      section.questions?.forEach(question => {
        var row = []
        row.push(++index)
        row.push(section.orderno)
        row.push(question.orderno)
        row.push(question.content)
        answers?.forEach(participant => {
          row.push(question[participant.answerorder].score)
          row.push(question[participant.answerorder].value)
        })
        datas.push(row)
      });
    });

    return datas
  }

  const getUseDataset = (eCRF, sections, answers, csv) => {

    var group = eCRF ? ["", "profile", "", ""] : ["profile"]
    var columns1 = eCRF ? ["ID", "group", "group", "Date of written consent"] : ["ID"]
    var columns2 = eCRF ? ["", "", "", ""] : [""]
    var datas = csv ? [group, columns1, columns2] : []

    answers?.forEach((participant, k) => {
      var row = []
      row.push(eCRF ? participant.initial : participant.accesstoken)
      if (eCRF) {
        row.push(participant.affiliation)
        row.push(participant.participantgroup)
        row.push(participant.agreementdate)
      }

      sections?.forEach((section, i) => {
        section.questions?.forEach((question, j) => {
          if (csv && k == 0) {
            group.push(j == 0 ? `section ${section.orderno}` : "")
            group.push("")
            columns1.push(`${section.orderno}-${question.orderno}`)
            columns1.push("")
            columns2.push("score")
            columns2.push("value")
          }
          row.push(question[participant.answerorder].score)
          row.push(question[participant.answerorder].value)
        });
        if (csv && k == 0) {
          group.push("")
          group.push("")
          group.push("")
          columns1.push(`Subtotal_secion${section.orderno}`)
          columns1.push("Response_Start_time")
          columns1.push("Response_End_time ")
          columns2.push("")
          columns2.push("")
          columns2.push("")
        }
        row.push(section[participant.answerorder].total?? "")
        row.push(section[participant.answerorder].starttime?? "")
        row.push(section[participant.answerorder].lasttime?? "")
      });
      datas.push(row)
    });
    return datas
  }

  const getStorageDataset = (eCRF, sections, answers, csv) => {

    var group = eCRF ? ["", "", ""] : ["", ""]
    var columns1 = eCRF ? ["ID", "Response_ID", "IP"] : ["ID", "IP"]
    var columns2 = eCRF ? ["", "", ""] : ["", ""]
    var datas = csv ? [group, columns1, columns2] : []

    answers?.forEach((participant, k) => {
      var row = []
      if (eCRF) row.push(participant.initial)
      row.push(participant.accesstoken)
      row.push(participant.ipatcompleted)

      sections?.forEach((section, i) => {
        section.questions?.forEach((question, j) => {
          if (csv && k == 0) {
            group.push(j == 0 ? `section ${section.orderno}` : "")
            group.push("")
            columns1.push(`${section.orderno}-${question.orderno}`)
            columns1.push("")
            columns2.push("score")
            columns2.push("value")
          }
          row.push(question[participant.answerorder].score)
          row.push(question[participant.answerorder].value)
        });
        if (csv && k == 0) {
          group.push("")
          group.push("")
          group.push("")
          columns1.push(`Subtotal_secion${section.orderno}`)
          columns1.push("Response_Start_time")
          columns1.push("Response_End_time ")
          columns2.push("")
          columns2.push("")
          columns2.push("")
        }
        row.push(section[participant.answerorder].total?? "")
        row.push(section[participant.answerorder].starttime?? "")
        row.push(section[participant.answerorder].lasttime?? "")
      });
      datas.push(row)
    });

    return datas
  }

  const saveCsv = async (sections, answers) => {

    const raw = getRawDataset(project.projectData.eCRF === 1, sections, answers, true)
    const use = getUseDataset(project.projectData.eCRF === 1, sections, answers, true)
    const storage = getStorageDataset(project.projectData.eCRF === 1, sections, answers, true)

    const date =  moment().format('YYYYMMDD_HHmmss')

    const zip = new JSZip()
    zip.file("raw_data.csv", makeCsv(raw,));
    zip.file("data_for_use.csv", makeCsv(use));
    zip.file("data_for_storage.csv", makeCsv(storage));
    zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, `${survey.surveyData.name}_${date}.zip`);
    });
  }

  const makeCsv = async (rows) => {

    const separator = ',';
    const keys  = Object.keys(rows[0]);

    const csvContent = `${keys.join(separator)}\n${
      rows.map((row) => keys.map((k) => {
        let cell = row[k] === null || row[k] === undefined ? '' : row[k];
        cell = cell instanceof Date
          ? cell.toLocaleString()
          : cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
    }).join(separator)).join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=euc-kr;' });
    return blob
  }

  const saveExcel = async (sections, answers) => {

    const workbook = new ExcelJS.Workbook();

    makeRawDataset(project.projectData.eCRF === 1, workbook, sections, answers)
    makeUseDataset(project.projectData.eCRF === 1, workbook, sections, answers)
    makeStorageDataset(project.projectData.eCRF === 1, workbook, sections, answers)

    const buffer = await workbook.xlsx.writeBuffer();
    const date =  moment().format('YYYYMMDD_HHmmss')
    saveAs(new Blob([buffer]), `${survey.surveyData.name}_${date}.xlsx`);
  }

  const makeHeader = (cell, alignment, fill, bold) => {

    cell.alignment = {
      horizontal: alignment,
      vertical: "middle"
    };
    if (bold) {
      cell.font = {
        bold: true
      }
    }
    if (fill) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffe0e0e0" },
      }
      cell.border = {
        top: {style:'thin', color: {argb:'FFFFFFFF'}},
        left: {style:'thin', color: {argb:'FFFFFFFF'}},
        bottom: {style:'thin', color: {argb:'FFFFFFFF'}},
        right: {style:'thin', color: {argb:'FFFFFFFF'}},
      };
    }
  }

  const makeRawDataset = (eCRF, workbook, sections, answers) => {

    const datas = getRawDataset(eCRF, sections, answers, false)
    const worksheet = workbook.addWorksheet('raw data');

    const headerArray = ["Timestamp", "IP", "question"];
    headerArray.forEach((headerText, index) => {
      const rowIndex = index + 1;
      worksheet.mergeCells(rowIndex, 1, rowIndex, 4);
      const cell = worksheet.getRow(rowIndex).getCell(1);
      cell.value = headerText;
      makeHeader(cell, "center", true);
    });


    const questionSubArray = ["No.", "section", "question", ""];
    questionSubArray.forEach((subHeaderText, index) => {
      const cell = worksheet.getRow(4).getCell(index + 1);
      cell.value = subHeaderText;
      makeHeader(cell, "center", true)
    });

    const widthArray = [10, 10, 10, 50];
    widthArray.forEach((width, index) => worksheet.getColumn(index + 1).width = width);

    for (let i = 1; i <= 3; i++) {
      worksheet.getColumn(i).alignment = {
        horizontal: "center",
        vertical: "middle"
      };
    }

    worksheet.addRows(datas);

    for(let i=0; i<answers.length; i++) {

      let participant = answers[i]
      const colStartIndex = 5 + i * 2;
      const colEndIndex = colStartIndex + 1;
      worksheet.mergeCells(1, colStartIndex, 1, colEndIndex);
      worksheet.getRow(1).getCell(colStartIndex).value = participant.completeddate?? ""

      worksheet.mergeCells(2, colStartIndex, 2, colEndIndex);
      worksheet.getRow(2).getCell(colStartIndex).value = participant.ipatcompleted?? ""

      worksheet.mergeCells(3, colStartIndex, 3, colEndIndex);
      worksheet.getRow(3).getCell(colStartIndex).value = eCRF ? participant.initial?? "" : participant.accesstoken?? ""

      worksheet.getRow(4).getCell(colStartIndex).value = "score"
      worksheet.getRow(4).getCell(colEndIndex).value = "value"

      worksheet.getColumn(colStartIndex).width = 20
      worksheet.getColumn(colEndIndex).width = 20

      worksheet.getColumn(colStartIndex).alignment = {
        horizontal: "right",
        vertical: "middle"
      };
      worksheet.getColumn(colEndIndex).alignment = {
        horizontal: "left",
        vertical: "middle"
      };
      makeHeader(worksheet.getRow(1).getCell(colStartIndex), "center", false)
      makeHeader(worksheet.getRow(2).getCell(colStartIndex), "right", false)
      makeHeader(worksheet.getRow(3).getCell(colStartIndex), "center", true)
      makeHeader(worksheet.getRow(4).getCell(colStartIndex), "center", true)
      makeHeader(worksheet.getRow(4).getCell(colEndIndex), "center", true)
    }
  }

  const makeUseDataset = (eCRF, workbook, sections, answers) => {

    const datas = getUseDataset(eCRF, sections, answers, false)

    const worksheet = workbook.addWorksheet('data for use');

    const start = eCRF ? 5 : 2


    for(let i=1; i<start; i++) {
      worksheet.getColumn(i).width = 20
    }

    if (eCRF) {
      worksheet.mergeCells(1, 2, 1, 4);
      worksheet.getRow(1).getCell(2).value = "profile"
      makeHeader(worksheet.getRow(1).getCell(2), "center", false, true)

      worksheet.mergeCells(2, 1, 3, 1);
      worksheet.getRow(2).getCell(1).value = "ID"
      makeHeader(worksheet.getRow(2).getCell(1), "center", true)

      worksheet.mergeCells(2, 2, 3, 2);
      worksheet.getRow(2).getCell(2).value = "group"
      makeHeader(worksheet.getRow(2).getCell(2), "center", true)

      worksheet.mergeCells(2, 3, 3, 3);
      worksheet.getRow(2).getCell(3).value = "group"
      makeHeader(worksheet.getRow(2).getCell(3), "center", true)

      worksheet.mergeCells(2, 4, 3, 4);
      worksheet.getRow(2).getCell(4).value = "Date of written consent"
      makeHeader(worksheet.getRow(2).getCell(4), "center", true)

    } else {
      worksheet.getRow(1).getCell(1).value = "profile"
      makeHeader(worksheet.getRow(1).getCell(1), "center", false, true)

      worksheet.mergeCells(2, 1, 3, 1);
      worksheet.getRow(2).getCell(1).value = "ID"
      makeHeader(worksheet.getRow(2).getCell(1), "center", true)

      worksheet.getColumn(1).width = 50
    }

    var index = 0
    for(let i=0; i<sections.length; i++) {
      const section = sections[i]

      worksheet.mergeCells(1, start + index, 1, start + (index + section.questions?.length) + 3 - 1);
      worksheet.getRow(1).getCell(start + index).value = `section ${section.orderno}`
      makeHeader(worksheet.getRow(1).getCell(start + index), "center", false, true)

      for(let j=0; j<section.questions?.length; j++) {
        const question = section.questions[j]

        worksheet.getColumn(start + index).width = 10
        worksheet.getColumn(start + index + 1).width = 10

        worksheet.mergeCells(2, start + index, 2, start + index + 1);
        worksheet.getRow(2).getCell(start + index).value = `${section.orderno}-${question.orderno}`
        makeHeader(worksheet.getRow(2).getCell(start + index), "center", true)

        worksheet.getRow(3).getCell(start + index).value = "score"
        makeHeader(worksheet.getRow(3).getCell(start + index), "center", true)

        worksheet.getRow(3).getCell(start + index + 1).value = "value"
        makeHeader(worksheet.getRow(3).getCell(start + index + 1), "center", true)

        index += 2
      }
      worksheet.mergeCells(2, start + index, 3, start + index);
      worksheet.getRow(2).getCell(start + index).value = `Subtotal_secion${section.orderno}`
      makeHeader(worksheet.getRow(2).getCell(start + index), "center", true)

      worksheet.mergeCells(2, start + index + 1, 3, start + index + 1);
      worksheet.getRow(2).getCell(start + index + 1).value = "Response_Start_time"
      makeHeader(worksheet.getRow(2).getCell(start + index + 1), "center", true)

      worksheet.mergeCells(2, start + index + 2, 3, start + index + 2);
      worksheet.getRow(2).getCell(start + index + 2).value = "Response_End_time"
      makeHeader(worksheet.getRow(2).getCell(start + index + 2), "center", true)

      worksheet.getColumn(start + index).width = 20
      worksheet.getColumn(start + index + 1).width = 20
      worksheet.getColumn(start + index + 2).width = 20

      index += 3
    }

    for(let i=1; i<start; i++) {
      worksheet.getColumn(i).alignment = {
        horizontal: "center",
        vertical: "middle"
      };
    }
    worksheet.addRows(datas);
  }

  const makeStorageDataset = (eCRF, workbook, sections, answers) => {

    const datas = getStorageDataset(eCRF, sections, answers, false)

    const worksheet = workbook.addWorksheet('data for storage');

    const start = eCRF ? 4 : 3
    for(let i=1; i<start; i++) {
      worksheet.getColumn(i).alignment = {
        horizontal: "center",
        vertical: "middle"
      };
    }

    if (eCRF) {

      worksheet.mergeCells(2, 1, 3, 1);
      worksheet.getRow(2).getCell(1).value = "ID"
      makeHeader(worksheet.getRow(2).getCell(1), "center", true)

      worksheet.mergeCells(2, 2, 3, 2);
      worksheet.getRow(2).getCell(2).value = "Response_ID"
      makeHeader(worksheet.getRow(2).getCell(2), "center", true)

      worksheet.mergeCells(2, 3, 3, 3);
      worksheet.getRow(2).getCell(3).value = "IP"
      makeHeader(worksheet.getRow(2).getCell(3), "center", true)

      worksheet.getColumn(2).width = 50
      worksheet.getColumn(3).width = 20

    } else {

      worksheet.mergeCells(2, 1, 3, 1);
      worksheet.getRow(2).getCell(1).value = "ID"
      makeHeader(worksheet.getRow(2).getCell(1), "center", true)

      worksheet.mergeCells(2, 2, 3, 2);
      worksheet.getRow(2).getCell(2).value = "IP"
      makeHeader(worksheet.getRow(2).getCell(2), "center", true)

      worksheet.getColumn(1).width = 50
      worksheet.getColumn(2).width = 20
    }

    var index = 0
    for(let i=0; i<sections.length; i++) {
      const section = sections[i]

      worksheet.mergeCells(1, start + index, 1, start + (index + section.questions?.length) + 3 - 1);
      worksheet.getRow(1).getCell(start + index).value = `section ${section.orderno}`
      makeHeader(worksheet.getRow(1).getCell(start + index), "center", false, true)

      for(let j=0; j<section.questions?.length; j++) {
        const question = section.questions[j]

        worksheet.getColumn(start + index).width = 10
        worksheet.getColumn(start + index + 1).width = 10

        worksheet.mergeCells(2, start + index, 2, start + index + 1);
        worksheet.getRow(2).getCell(start + index).value = `${section.orderno}-${question.orderno}`
        makeHeader(worksheet.getRow(2).getCell(start + index), "center", true)

        worksheet.getRow(3).getCell(start + index).value = "score"
        makeHeader(worksheet.getRow(3).getCell(start + index), "center", true)

        worksheet.getRow(3).getCell(start + index + 1).value = "value"
        makeHeader(worksheet.getRow(3).getCell(start + index + 1), "center", true)

        index += 2
      }
      worksheet.mergeCells(2, start + index, 3, start + index);
      worksheet.getRow(2).getCell(start + index).value = `Subtotal_secion${section.orderno}`
      makeHeader(worksheet.getRow(2).getCell(start + index), "center", true)

      worksheet.mergeCells(2, start + index + 1, 3, start + index + 1);
      worksheet.getRow(2).getCell(start + index + 1).value = "Response_Start_time"
      makeHeader(worksheet.getRow(2).getCell(start + index + 1), "center", true)

      worksheet.mergeCells(2, start + index + 2, 3, start + index + 2);
      worksheet.getRow(2).getCell(start + index + 2).value = "Response_End_time"
      makeHeader(worksheet.getRow(2).getCell(start + index + 2), "center", true)

      worksheet.getColumn(start + index).width = 20
      worksheet.getColumn(start + index + 1).width = 20
      worksheet.getColumn(start + index + 2).width = 20

      index += 3
    }

    for(let i=1; i<start; i++) {
      worksheet.getColumn(i).alignment = {
        horizontal: "center",
        vertical: "middle"
      };
    }

    worksheet.addRows(datas);
  }

  const getAnswer = (datas, sectionorderno, questionorderno) => {
    return datas.filter(data => data.sectionorderno === sectionorderno && data.questionorderno == questionorderno)?.[0]
  }

  const getScore = (question, content) => {

    if (`${content}`.length === 0) return null

    if (question.questiontype == SURVEY_QUESTION_TYPE_ESSAY
      || question.questiontype == SURVEY_QUESTION_TYPE_DATE) {
      return "-"
    } else if (question.scoring !== 1) {
      // var score = ""
      // var ordernos = content?.split(",")?? []
      // ordernos.forEach(orderno => {
      //   const item = question.items.filter(item => Number(item.orderno) === Number(orderno))?.[0]
      //   if (!!item) {
      //     if (score.length > 0) score += ", "
      //     score += item.content
      //   }
      // })
      // return score
      return "-"
    } else {
      var score = 0
      var ordernos = content?.split(",")?? []
      ordernos.forEach(orderno => {
        const item = question.items.filter(item => Number(item.orderno) === Number(orderno))?.[0]
        if (!!item) score += item.score
      })
      return score
    }
    return null
  }

  const getValue = (question, content, reason) => {
    if (`${content}`.length === 0) return null

    if (question.questiontype == SURVEY_QUESTION_TYPE_ESSAY
      || question.questiontype == SURVEY_QUESTION_TYPE_DATE) {
      return content
    } else {
      var values = []
      var ordernos = content?.split(",")?? []
      ordernos.forEach(orderno => {
        const item = question.items.filter(item => Number(item.orderno) === Number(orderno))?.[0]
        if (!!item) {
          if (reason.length > 0 && (item.isetc === 1 || question.inputreason === 1)) {
            values.push(`${item.content}:${reason}`)
          } else {
            values.push(item.content)
          }
        }
      })
      return values.join(", ")
    }
  }

  return (

    <div className="body project SurveyAnswer">
      <div className="container">
        <div className="inwrap">
          <div className="full ">
          {/* 상단 */}
          <section className="tab_wrap">
            <div className="top_wrap" style={{minHeight:"40px"}}>
              <h5 className='bold'>응답 {survey.answerCount?? 0}개</h5>
              <div>
                {project.projectData?.eCRF === 1 && <button className="secondary gray large" onClick={onClickUpload} >컨펌용 엑셀 {fileCount > 0 ? `(${fileCount})` : ``}</button>}
                {dataCount > 0 && <button className="secondary gray large" onClick={() => onClickDownload("excel")}>엑셀 다운로드</button>}
                {dataCount > 0 && <button className="secondary gray large" onClick={() => onClickDownload("csv")}>CSV 다운로드</button>}
              </div>
            </div>
            <div className='top_wrap menu_wrap'>
              <ul className='sub_menu'>
                <li className={selectedTab !== "individual" ? "active" : ""}><a onClick={() => {onChangeTab("summary")}}>요약보기</a></li>
                <li className={selectedTab === "individual" ? "active" : ""}><a onClick={() => {onChangeTab("individual")}}>개별보기</a></li>
              </ul>
              { project.projectData?.eCRF === 1 && project.projectData?.isowner === 1  &&
                <ul className="tab">
                  <li className={affiliation !== "MY" ? "active" : ""} onClick={() => onChangeAffiliation("ALL")}>전체</li>
                  <li className={affiliation === "MY" ? "active" : ""} onClick={() => onChangeAffiliation("MY")}>내 기관</li>
                </ul>
              }
            </div>
          </section>
          {selectedTab === "individual" ?
            <SurveyAnswerIndividual
              affiliation={affiliation}
              participant={participant.current}
              answer={idanswer.current}
            />  : <SurveyAnswerSummary
              affiliation={affiliation}
            />
          }
         </div>
        </div>
      </div>
    </div>
  )
}
export default SurveyAnswerContent;